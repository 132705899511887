




























































































































































































































































































































































































































































.form-inline {
  width: 45%;
  p{
    width: 15rem;
  }
}

.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > .handle {
        // color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.butbox {
  display: flex;
  justify-content: center;
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
